<template>
  <div>
    <v-navigation-drawer
      v-model="drawer.state"
      fixed
      temporary
      right
      color="transparent"
      class="drawer-background"
      app
      clipped
      :style="`margin-top: ${$vuetify.application.top}px`"
      width="100%"
    >
      <v-row v-if="drawer.state">
        <!-- <v-col cols="12" class="text-center mt-12">
          <span
            class="white--text d-block"
            style="font-weight: bold; font-size: 1rem"
          >
            {{ $t("lp_change_language") }}
          </span>
          <v-btn
            icon
            v-for="entry in languages"
            :key="entry.title"
            @click="changeLocale(entry.language)"
            class="language-flag mx-1"
          >
            <flag
              style="font-size: 1.2rem"
              :iso="entry.flag"
              v-bind:squared="false"
              class="mr-0"
            />
          </v-btn>
        </v-col> -->

        <v-col cols="12" class="text-center pt-12 white--text">
          <v-card
            flat
            tile
            color="transparent"
            :to="{
              name: route.name,
            }"
            v-for="(route, index) in landing_routes"
            :key="route.name"
            class="my-4"
            :style="[
              route.name == currentRouteName
                ? { cursor: 'default' }
                : { cursor: 'pointer' },
            ]"
          >
            <textLuminance
              :string="route.text"
              :isCurrentRoute="route.name == currentRouteName"
              fontSize="24px"
              :animationDelay="index * 0.25 + 's'"
              class="d-inline mx-2"
            />
          </v-card>
        </v-col>
      </v-row>

      <!-- <v-card height="300"> Ciao </v-card> -->
    </v-navigation-drawer>
  </div>
</template>

<script> 
import textLuminance from "../effects/textLuminance.vue";
import { languages } from "@/assets/translations/languages";
import { i18n } from "@/plugins/i18n";
import { gtag } from "@/gtag";

export default {
  props: {
    drawer: {
      type: Object,
    },
  },
  components: {
    textLuminance,
  },
  data: () => ({
    languages: languages,
  }),
  methods: {
    changeView(routeName) {
      this.$router.replace(routeName);
    },
    changeLocale(locale) {
      // Do something only if language is different form current language
      if (locale != i18n.locale) {
        i18n.locale = locale;
        gtag("event", "switch_language", {
          language: locale,
        });
      }
      this.drawer.state = false;

      // if (locale != i18n.locale) {
      //   this.$router.push({
      //     name: "LandingHome",
      //     params: { language: locale },
      //   });
      // }
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {
    drawer(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
/* .drawer-background {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.85) 70%,
    rgba(0, 0, 0, 1) 90%
  );
  background-size: 400% 400%;
  animation: gradient 1s ease infinite;
} */

.drawer-background {
  background: linear-gradient(214deg, #00000092, #000000, #000000be);
  background-size: 200% 200%;

  -webkit-animation: AnimationName 5s ease infinite;
  -moz-animation: AnimationName 5s ease infinite;
  animation: AnimationName 5s ease infinite;
}

/* .drawer-backgroundt {
  animation: animateBg 5s linear infinite;
  -webkit-animation: animateBg 5s ease infinite;
  -moz-animation: animateBg 5s ease infinite;
  animation: animateBg 5s ease infinite;
  background-image: linear-gradient(
    0deg,
    #000000,
    #0d0d0d,
    #262626,
    #000000,
    #0d0d0d
  );
  background-size: 400% 400%;
}
@keyframes animateBg {
  0% {
    background-position: 0% 200%;
  }
  100% {
    background-position: 200% 0%;
  }
} */
</style>