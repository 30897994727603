//Importo Firebase
//Documentazione: https://firebase.google.com/docs
import firebase from 'firebase/app';
// import 'firebase/firestore';
// import 'firebase/auth';
import 'firebase/functions'
// import 'firebase/storage'
// import 'firebase/analytics'
// import 'firebase/messaging'



const VAPID_prod = "BAYmYZDP-SymimXreE1bpRtvLcJHniZcG95Qb3f6U5cIHu5aMhjk9wI4ql2m1DTXqcd2cHmBZp7w5s1dlRx9R3g"

export const gmaps_apikey = "AIzaSyAgwYrvKnQQTFwvy3CBvlPI6ukUzTRlIK8"

let firebaseConfig_prod = {
  apiKey: "AIzaSyAgwYrvKnQQTFwvy3CBvlPI6ukUzTRlIK8",
  authDomain: "avm2-safetyfirst.firebaseapp.com",
  projectId: "avm2-safetyfirst",
  storageBucket: "avm2-safetyfirst.appspot.com",
  messagingSenderId: "530093329114",
  appId: "1:530093329114:web:b15b57efd4a61cdc7973e3",
  // measurementId: "G-Q5MKSKS7LE"
};


firebase.initializeApp(firebaseConfig_prod);

// // If supported create firebase messaging
// let fbm
// if (firebase.messaging.isSupported()) {
//   // console.log("messaging is supported")
//   fbm = firebase.messaging();
// } else {
//   // console.log("messaging is NOT supported")
//   fbm = null
// }

// // Get token requiring user to enable browser notifications
// fbm.getToken({ vapidKey: VAPID_prod }).then((currentToken) => {
//   console.log(currentToken);
// }).catch((error) => {
//   console.log(error);
// })


// fbm.onMessage((payload) => {
//   console.log('Message received. ', payload);
//   // ...
// });



// export const firebaseMessaging = fbm
// export const firebaseMessaging_vapid = VAPID_prod
// // *********************************************

// firebase.functions().useFunctionsEmulator('http://localhost:5001') 

// var dbCloudFirestore = firebase.firestore();
var cloudFunctionsUrlRequestEndpoint = "https://europe-west1-avm2-safetyfirst.cloudfunctions.net/"

let functions
if (location.hostname === "localhost") {
  // dbCloudFirestore.settings({
  //   host: "localhost:8085",
  //   ssl: false
  // });
  cloudFunctionsUrlRequestEndpoint = "http://localhost:5001/avm2-safetyfirst/us-central1"
}

// if (location.hostname === "192.168.0.100") {
//   dbCloudFirestore.settings({
//     host: "192.168.0.100:8085",
//     ssl: false
//   });
// }


if (location.hostname === "localhost") {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
  functions = firebase.functions();
} else if (location.hostname === "192.168.0.100") {
  firebase.functions().useFunctionsEmulator('http://192.168.0.100:5001')
  functions = firebase.functions()
} else {
  // functions = firebase.app().functions();
  functions = firebase.app().functions('europe-west1');
}



//////////////////////////////////////////////////////////////////

// Esporto i moduli di Firebase
// export const firebaseDatabase = firebase.database();
// export const firestore = dbCloudFirestore;
export const firebaseFunctions = functions;
// export const firebaseAuth = firebase.auth();
// export const firebaseAnalytics = firebase.analytics();

// gs://avm2-safetyfirst

// export const storageRef = firebase.storage().ref();

export const urlRequestEndpoint = cloudFunctionsUrlRequestEndpoint


// if (location.hostname === "localhost") {
//   firebaseAuth.useEmulator("http://localhost:9099");
// }


// if (location.hostname === "192.168.0.100") {
//   firebaseAuth.useEmulator("http://192.168.0.100:9099");
// }