<template>
  <!-- <div style="position: absolute; bottom: 0" class="mt-12 page_footer"> -->
    <div>
    <div
      style="background: black; width: 100vw"
      class="d-flex align-center justify-center white--text"
    >
      <!-- v-bind:style="{ height: footer_height }" -->
      <v-row no-gutters>
        <v-col cols="12" class="pt-12 text-center white black--text">
          <span class="label_follow_us" v-html="$t('label_follow_us')"></span>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center white black--text pb-12"
          style="padding-bottom: 1rem"
        >
          <v-card
            width="40"
            height="40"
            color="rgb(66, 103, 178)"
            class="mx-1 d-flex justify-center align-center"
            href="https://www.facebook.com/safirstcom"
            target="_blank"
          >
            <v-icon color="white">mdi-facebook</v-icon>
          </v-card>

          <v-card
            width="40"
            height="40"
            class="mx-1 d-flex justify-center align-center"
            color="gray"
            href="https://www.instagram.com/sa_first_com/"
            target="_blank"
          >
            <v-icon class="logo_instagram">mdi-instagram</v-icon>
          </v-card>

          <v-card
            width="40"
            height="40"
            color="#0077b5"
            class="mx-1 d-flex justify-center align-center"
            href="https://www.linkedin.com/showcase/sa-first/"
            target="_blank"
          >
            <v-icon color="white">mdi-linkedin</v-icon>
          </v-card>
        </v-col>

        <v-col cols="12" class="text-center py-12 px-6">
          <!-- <btnCtaCollectContactHeading formName="page_footer" /> -->
        </v-col>

        <v-col
          cols="12"
          class="pt-12 text-center label_sharing"
          v-if="!small_button"
        >
          <span v-html="$t('label_share_social')"></span>
        </v-col>
        <v-col
          cols="12"
          v-bind:class="small_button ? 'pt-12' : ''"
          class="pt-0 pb-4 text-center row_socials"
        >
          <ShareNetwork
            network="facebook"
            url="https://sa-first.com"
            :popup="{ width: 400, height: 200 }"
            :title="$t('lp_share_title')"
            :description="$t('lp_share_description')"
            :quote="$t('lp_share_quote')"
            hashtags="SafetyFirst, SicurezzaSulLavoro, Infortuni"
            style="text-decoration: none !important"
            class="mx-1"
          >
            <v-btn
              v-if="small_button"
              small
              color="#4267B2"
              class="no-uppercase px-2"
              @click="shareOn('facebook')"
            >
              <v-icon color="white">mdi-facebook</v-icon>
              <span class="ml-2 white--text" style="font-size: 0.75rem">{{
                $t("lp_share_facebook")
              }}</span>
            </v-btn>

            <v-btn
              v-if="!small_button"
              color="transparent"
              class="no-uppercase px-2"
              fab
              small
              @click="shareOn('facebook')"
            >
              <v-icon color="#4267B2">mdi-facebook</v-icon>
            </v-btn>
          </ShareNetwork>

          <ShareNetwork
            network="email"
            url="https://sa-first.com"
            :popup="{ width: 400, height: 200 }"
            :title="$t('lp_share_title')"
            :description="$t('lp_share_description')"
            :quote="$t('lp_share_quote')"
            hashtags="SafetyFirst, SicurezzaSulLavoro, Infortuni"
            style="text-decoration: none !important"
            class="mx-1"
          >
            <v-btn
              v-if="small_button"
              small
              color="#DB4437"
              class="no-uppercase px-2"
              @click="shareOn('email')"
            >
              <v-icon color="white">mdi-gmail</v-icon>
              <span class="ml-2 white--text" style="font-size: 0.75rem">{{
                $t("lp_share_email")
              }}</span>
            </v-btn>
            <v-btn
              v-if="!small_button"
              color="transparent"
              class="no-uppercase px-2"
              fab
              small
              @click="shareOn('email')"
            >
              <v-icon color="#DB4437">mdi-gmail</v-icon>
            </v-btn>
          </ShareNetwork>
          <ShareNetwork
            network="whatsapp"
            url="https://sa-first.com"
            :popup="{ width: 400, height: 200 }"
            :title="$t('lp_share_title')"
            :description="$t('lp_share_description')"
            :quote="$t('lp_share_quote')"
            hashtags="SafetyFirst, SicurezzaSulLavoro, Infortuni"
            style="text-decoration: none !important"
            class="mx-1"
          >
            <v-btn
              v-if="small_button"
              small
              color="#25D366"
              class="no-uppercase px-2"
              @click="shareOn('whatsapp')"
            >
              <v-icon color="white">mdi-whatsapp</v-icon>
              <span class="ml-2 white--text" style="font-size: 0.75rem">{{
                $t("lp_share_whatsapp")
              }}</span>
            </v-btn>
            <v-btn
              v-if="!small_button"
              color="transparent"
              class="no-uppercase px-2"
              fab
              small
              @click="shareOn('whatsapp')"
            >
              <v-icon color="#25D366">mdi-whatsapp</v-icon>
            </v-btn>
          </ShareNetwork>
          <ShareNetwork
            network="linkedin"
            url="https://sa-first.com"
            :popup="{ width: 400, height: 200 }"
            :title="$t('lp_share_title')"
            :description="$t('lp_share_description')"
            :quote="$t('lp_share_quote')"
            hashtags="SafetyFirst, SicurezzaSulLavoro, Infortuni"
            style="text-decoration: none !important"
            class="mx-1"
          >
            <v-btn
              v-if="small_button"
              small
              color="#0077b5"
              class="no-uppercase px-2"
              @click="shareOn('linkedin')"
            >
              <v-icon color="white">mdi-linkedin</v-icon>
              <span class="ml-2 white--text" style="font-size: 0.75rem">{{
                $t("lp_share_linkedin")
              }}</span>
            </v-btn>
            <v-btn
              v-if="!small_button"
              color="transparent"
              class="no-uppercase px-2"
              fab
              small
              @click="shareOn('linkedin')"
            >
              <v-icon color="#0077b5">mdi-linkedin</v-icon>
            </v-btn>
          </ShareNetwork>
        </v-col>
        <v-col cols="12" class="mt-4 text-center">
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-center">
              <!-- <span style="font-size: 0.8rem" class="mr-2 d-flex align-center">
                Made with <span class="pulsing_heart">❤️</span> by
              </span> -->
              <a href="https://tobin.cloud" target="_blank">
                <img
                  class="d-block"
                  src="@/assets/graphics/tobin.png"
                  srcset="
                    @/assets/graphics/tobin.png 2x
                  "
                  contain
                  style="background-position: initial !important; height: 48px"
                  alt="Tobin logo"
                />
              </a>
            </v-col>
            <v-col cols="12" class="text-center">
              <span class="white--text" style="font-size: 0.75rem">
               Tobin <br />
                P.IVA: 03803230048 
              </span>
            </v-col>
            <v-col cols="12" class="black">
              <a
                class="footer_links"
                @click="open_cookie_settings()"
                style="color: white"
                >Cookie</a
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { gtag } from "@/gtag";
import AOS from "aos";
import "aos/dist/aos.css";
import { EventBus } from "@/main";
import btnCtaCollectContactHeading from "@/components/landings/blocks/btnCtaCollectContactHeading";

export default {
  props: {},
  components: {
    btnCtaCollectContactHeading,
  },
  data: () => ({}),
  methods: {
    open_cookie_settings() {
      EventBus.$emit("open_cookie_setting");
    },

    shareOn(social) {
      console.log("shareOn", social);
      gtag("event", "social_share", {
        social: social,
      });
    },
  },
  mounted() {},
  created() {},
  computed: {
    small_button() {
      if (this.$vuetify.breakpoint.xs) {
        return false;
      } else if (this.$vuetify.breakpoint.sm) {
        return false;
      } else if (this.$vuetify.breakpoint.md) {
        return true;
      } else if (this.$vuetify.breakpoint.lg) {
        return true;
      } else if (this.$vuetify.breakpoint.xl) {
        return true;
      }
    },

    footer_height() {
      return "200px";
      if (this.$vuetify.breakpoint.xs) {
        return "120px";
      } else if (this.$vuetify.breakpoint.sm) {
        return "120px";
      } else if (this.$vuetify.breakpoint.md) {
        return "120px";
      } else if (this.$vuetify.breakpoint.lg) {
        return "120px";
      } else if (this.$vuetify.breakpoint.xl) {
        return "120px";
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.pulsing_heart {
  animation-duration: 1s;
  animation-name: pulse;
  animation-iteration-count: infinite;
  margin-left: 2px;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.1);
  }
}

.label_follow_us,
.label_sharing {
  font-size: 0.75rem;
}

.logo_instagram {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>