/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    // updatefound() {
    //   console.log('New content is downloading.')
    //   let key_changed = 0
    //   caches.keys().then(function (names) {
    //     for (let name of names) {
    //       console.log('delete cache', name);
    //       caches.delete(name)
    //       key_changed = key_changed + 1
    //     }
    //     if (key_changed > 0) {
    //       console.log('reload the page');
    //       // location.reload()
    //     }
    //   })
    // },
    // updated () {
    //   console.log('New content is available; please refresh.')
    // },
    updated(registration) {
      console.log('New content is available; please refresh.')
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      )
    },
    // updated() {
    //   console.log('New content is available; please refresh.');
    // },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
