<template>
  <div>
    <v-card class="cta_demo" @click="requestDemo(formName)">
      <v-card-text class="black--text">
        <strong class="btn_cta_h2">
          <h2>{{ $t("lp_btn_cta_span_1") }}</h2>
        </strong>

        <p style="margin-bottom: 0px">
          {{ $t("lp_btn_cta_span_2") }}
          {{ $t("lp_btn_cta_span_3") }}
          {{ $t("lp_btn_cta_span_4") }}
        </p>
        <!-- <p style="margin-bottom: 0px">
          {{ $t("lp_btn_cta_span_3") }}
        </p> -->
        <!-- <p style="margin-bottom: 0px">
          {{ $t("lp_btn_cta_span_4") }}
        </p> -->
      </v-card-text>
    </v-card>
    <!-- <dialogCollectContact
      :header="$t('lp_dialog_cta_heading')"
      :formName="formName"
    /> -->
  </div>
</template>

<script>
import { EventBus } from "@/main";
// import dialogCollectContact from "./dialogCollectContact.vue";

export default {
  props: {
    formName: {
      type: String,
    },
  },
  components: {
    // dialogCollectContact,
  },
  data: () => ({}),
  methods: {
    requestDemo(calling_section) {
      EventBus.$emit("cta_contact_collect", calling_section);
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>