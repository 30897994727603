<template>
  <div>
    <v-app-bar class="app_bar_background" app dense dark fixed clipped-right elevation="0" id="landing_top_bar" style="padding-top: 12px; padding-bottom: 12px; height: 64px">
      <v-app-bar-title>
        <!-- Logo Tobin -->
        <v-row no-gutters>
          <v-col>
            <img
              class="ma-3 mr-3 d-block mb-4"
              src="@/assets/graphics/tobin.png"
              srcset="@/assets/graphics/tobin.png 2x"
              height="36px"
              contain
              style="background-position: initial !important"
              :to="{
                name: 'LandingHome',
              }"
              alt="SafetyFirst logo"
              @click="logoClick()"
            />
          </v-col>
          <v-col>
            <img
              class="ma-3 mr-3 d-block mb-4"
              src="@/assets/graphics/sf-logo-white-yellow_64.png"
              srcset="@/assets/graphics/sf-logo-white-yellow_128.png 2x"
              height="36px"
              contain
              style="background-position: initial !important"
              :to="{
                name: 'LandingHome',
              }"
              alt="SafetyFirst logo"
              @click="logoClick()"
            />
          </v-col>
        </v-row>

        <!-- Logo -->
      </v-app-bar-title>

      <v-spacer></v-spacer>
      <div class="white--text" v-if="isSmallDev == false">
        <v-card
          flat
          tile
          color="transparent"
          class="d-inline"
          :to="{
            name: route.name,
          }"
          v-for="(route, index) in landing_routes"
          :key="route.name"
          :style="[route.name == currentRouteName ? { cursor: 'default' } : { cursor: 'pointer' }]"
        >
          <!-- v-if="route.name != 'LandingHome'" -->
          <textLuminance :isCurrentRoute="route.name == currentRouteName" :string="route.text" fontSize="16px" :animationDelay="index * 0.25 + 's'" class="d-inline mx-2" />

          <!-- <v-icon :to="{name: 'LandingHome'}" v-if="route.name == 'LandingHome'">
            mdi-home
          </v-icon> -->
        </v-card>

        <!-- Menu language select -->
        <!-- <div class="text-center d-inline" v-if="current_i18n">
          <v-menu offset-y dense class="menu_languages">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="primary" dark v-bind="attrs" v-on="on">
                <flag
                  :iso="current_i18n.locale"
                  v-bind:squared="false"
                  class="mr-0"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="px-0 d-flex justify-center"
                dense
                v-for="entry in languages"
                :key="entry.title"
              >
                <v-btn
                  icon
                  @click="changeLocale(entry.language)"
                  class="language-flag mx-1"
                >
                  <flag :iso="entry.flag" v-bind:squared="false" class="mr-0" />
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </div> -->
      </div>

      <!-- Go to the app btn -->
      <!-- <v-btn class="mx-3" @click="trackGoToApp()" small outlined color="yellow">
        {{ $t("btn_goto_app") }}
      </v-btn> -->

      <v-btn large icon class="mr-1" @click="open_close_drawer()" v-if="isSmallDev">
        <v-icon v-if="drawer.state == false" large color="white">mdi-menu</v-icon>
        <v-icon v-if="drawer.state == true" large color="white">mdi-close</v-icon>
      </v-btn>
    </v-app-bar>

    <lateralLandingDrawer :drawer="drawer" v-if="isSmallDev" />
  </div>
</template>

<script>
import textLuminance from "../effects/textLuminance.vue";
import lateralLandingDrawer from "./lateralLandingDrawer.vue";
import { languages } from "@/assets/translations/languages";
import { i18n } from "@/plugins/i18n";
import { gtag } from "@/gtag";

export default {
  props: {},
  components: {
    textLuminance,
    lateralLandingDrawer,
  },
  data: () => ({
    current_i18n: i18n,
    languages: languages,
    drawer: {
      state: false,
    },
    //
    fullscreen: false,
    fullscreen_btn_tooltip: null,
  }),
  methods: {
    logoClick() {
      this.scrollToId("home_header");
      this.$router.replace({ name: "LandingHome" });
    },
    scrollToId(id, withOffset = true) {
      console.log("scroll to id", id);
      if (withOffset) {
        const el = document.getElementById(id);
        if (el) {
          let distanceFromTop = el.getBoundingClientRect().top;
          let appBarHeight = document.getElementById("landing_top_bar").offsetHeight;
          let scroll_position = distanceFromTop - appBarHeight;
          window.scrollTo({ top: scroll_position, behavior: "smooth" });
        }
      } else {
        const el = document.getElementById(id);
        if (el) {
          el.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    },

    goFullScreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        console.log("go fullscreen");
        document.documentElement.requestFullscreen();
        setTimeout(() => {
          this.fullscreen_btn_tooltip = this.$t("tooltip_exit_fullscreen");
        }, 250);
      } else {
        document.exitFullscreen();
        setTimeout(() => {
          this.fullscreen_btn_tooltip = this.$t("tooltip_show_fullscreen");
        }, 250);
      }
    },
    open_close_drawer() {
      this.drawer.state = !this.drawer.state;
    },
    changeLocale(locale) {
      // Do something only if language is different form current language

      if (locale != i18n.locale) {
        i18n.locale = locale;

        gtag("event", "switch_language", {
          language: locale,
        });
      }
      // if (locale != i18n.locale) {
      //   this.$router.push({
      //     name: "LandingHome",
      //     params: { language: locale },
      //   });
      // }
    },
    trackGoToApp() {
      gtag("event", "goto_app", {});

      setTimeout(() => {
        window.location.href = "https://app.sa-first.com";
        // this.$router.push({ name: "Home" });
      }, 200);
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
.app_bar_background {
  /* background: rgba(0, 0, 0, 1) !important; */
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 2%,
    rgba(0, 0, 0, 0.25) 6%,
    rgba(0, 0, 0, 0.75) 10%,
    rgba(0, 0, 0, 1) 75%
  ) !important; */
  height: 80px;
  background: black !important;
  /* box-shadow: 0px 2px 10px black; */
  box-shadow: 0px 0px 20px black !important;
}

.v-menu__content.theme--dark.v-menu__content--fixed.menuable__content__active {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
</style>
