<template>
  <div>
    <v-bottom-sheet v-model="cookie_dialog" max-width="800" persistent>
      <v-card
        dark
        tile
        color="black pa-8"
        style="
          border-top-style: solid;
          border-top-color: yellow !important;
          border-bottom-style: solid;
          border-bottom-color: yellow !important;
          border-color: yellow !important;
          border-style: solid;
        "
      >
        <v-card-text class="pt-4 pb-0" style="font-size: 0.9rem">
          <div class="cookie_message">
            <h3>Informativa</h3>
            <span>
              Noi e terze parti selezionate utilizziamo cookie o tecnologie
              simili per finalità tecniche e, con il tuo consenso, anche per
              altre finalità (“interazioni e funzionalità semplici”,
              “miglioramento dell'esperienza”, “misurazione” e “targeting e
              pubblicità”) come specificato nella cookie policy.
            </span>
            <span>
              Puoi liberamente prestare, rifiutare o revocare il tuo consenso,
              in qualsiasi momento.
            </span>
            <span>
              Puoi acconsentire all’utilizzo di tali tecnologie utilizzando il
              pulsante “Accetta”.
            </span>

            <p>
              Per scoprirne di pià puoi consultare la nostra
              <strong class="white--text">
                <a style="color: white !important" @click="view_cookie_policy()"
                  >cookie policy</a
                >
              </strong>
              .
            </p>
          </div>

          <div v-if="cookie_settings">
            Impostazioni cookie
            <v-checkbox
              hide-details
              class="pt-0 mt-2 pb-2"
              v-model="cookies.analytics"
            >
              <template v-slot:label>
                <span style="font-size: 0.95rem; color: white">{{
                  $t("lp_cookie_google_analytics")
                }}</span>
              </template>
            </v-checkbox>

            <v-checkbox
              hide-details
              class="pt-0 mt-2 pb-2"
              v-model="cookies.linkedin_insight"
            >
              <template v-slot:label>
                <span style="font-size: 0.95rem; color: white"
                  >Linkedin Insight</span
                >
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="secondary"
            min-width=""
            text
            small
            @click="cookie_settings = !cookie_settings"
          >
            {{ $t("btn_customize") }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="secondary"
            min-width="100"
            outlined
            @click="saveCookiePermisison()"
          >
            {{ $t("btn_accept") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>

    <cookiePolicy />
  </div>
</template>

<script>
import { gtag } from "@/gtag";
import { EventBus } from "@/main";
import cookiePolicy from "@/components/landings/blocks/cookiePolicy.vue";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  // console.log(
  //   c_name +
  //     "=" +
  //     escape(value) +
  //     (expiredays == null ? "" : ";expires=" + exdate)
  // );
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (expiredays == null ? "" : ";expires=" + exdate);
}
// ----------------------------------

function get_cookie(name) {
  return document.cookie.split(";").some((c) => {
    return c.trim().startsWith(name + "=");
  });
}

function delete_cookie(name, path, domain) {
  if (get_cookie(name)) {
    document.cookie =
      name +
      "=" +
      (path ? ";path=" + path : "") +
      (domain ? ";domain=" + domain : "") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

export default {
  props: {},
  components: {
    cookiePolicy,
  },
  data: () => ({
    cookie_dialog: true,
    cookies: {
      analytics: true,
      linkedin_insight: true,
    },

    cookies_was: {
      analytics: null,
      linkedin_insight: null,
    },
    //
    cookie_settings: false,
  }),
  methods: {
    view_cookie_policy() {
      EventBus.$emit("open_cookie_policy");
    },
    saveCookiePermisison() {
      this.setCookieAcceptance("analytics_storage", this.cookies.analytics);
      this.setCookieAcceptance(
        "linkedin_insight",
        this.cookies.linkedin_insight
      );

      this.setCookieAcceptance("lp_cookies_decision", "answered");
      this.cookie_dialog = false;

      if (this.cookies.analytics) {
        this.consent_mode_grant_analytics();
        this.cookies_was.analytics = true;
      } else {
        this.consent_mode_revoke_analytics();
        this.cookies_was.analytics = false;
      }

      if (this.cookies.linkedin_insight) {
        // console.log("accepted linkedin insight");
        this.cookies_was.linkedin_insight = true;
        dataLayer.push({ event: "ck_accept_linkedin" });
      }

      if (
        this.cookies_was.analytics == true &&
        this.cookies.analytics == false
      ) {
        // console.log("decline analytics,reload");
        location.reload();
        //  Reload the page for unload now unwanted linkedin insight tag
      }

      if (
        this.cookies_was.linkedin_insight == true &&
        this.cookies.linkedin_insight == false
      ) {
        // console.log("decline linkedin insight,reload");
        location.reload();
        //  Reload the page for unload now unwanted linkedin insight tag
      }
    },
    setCookieAcceptance(cookie_type, value) {
      if (value == true) {
        setCookie(cookie_type, "granted");
      } else if (value == false) {
        setCookie(cookie_type, "denied");
      } else {
        setCookie(cookie_type, value);
      }
    },
    consent_mode_grant_analytics() {
      // console.log("consent_mode accept analytics");
      gtag("consent", "update", {
        analytics_storage: "granted",
      });
    },
    consent_mode_revoke_analytics() {
      // console.log("consent_mode revoke analytics");
      gtag("consent", "update", {
        analytics_storage: "denied",
      });
      setTimeout(() => {
        delete_cookie("_ga", "/", ".sa-first.com");
        delete_cookie("_ga", "/", "localhost");
        delete_cookie("_ga_0T5ZC36DSR", "/", ".sa-first.com");
        delete_cookie("_ga_0T5ZC36DSR", "/", "localhost");
      }, 1000);
    },
  },
  mounted() {
    if (getCookie("linkedin_insight") == "denied") {
      this.cookies_was.linkedin_insight = false;
      this.cookies.linkedin_insight = false;
    } else {
      this.cookies_was.linkedin_insight = true;
      this.cookies.linkedin_insight = true;
      if (getCookie("linkedin_insight") == "granted") {
        dataLayer.push({ event: "ck_accept_linkedin" });
      }
    }

    if (getCookie("analytics_storage") == "denied") {
      this.cookies_was.analytics = false;
      this.cookies.analytics = false;
    } else {
      this.cookies_was.analytics = true;
      this.cookies.analytics = true;
    }

    EventBus.$on("open_cookie_setting", () => {
      this.cookie_dialog = true;
    });

    gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
      wait_for_update: 2500,
    });
    gtag("set", "url_passthrough", true);
    gtag("set", "ads_data_redaction", true);

    setTimeout(() => {
      const lp_cookies_decision = getCookie("lp_cookies_decision");
      const analytics_storage = getCookie("analytics_storage");
      // const linkedin_insight = getCookie("linkedin_insight");
      // console.log("lp_cookies_decision", lp_cookies_decision);
      // console.log("analytics_storage", analytics_storage);
      if (!lp_cookies_decision) {
        this.cookie_dialog = true;
        // console.log("SHOW COOKIE DIALOG");
      } else {
        if (analytics_storage == "granted") {
          this.consent_mode_grant_analytics();
        } else {
          this.consent_mode_revoke_analytics();
        }
        // if (linkedin_insight == "granted") {
        //   dataLayer.push({ event: "ck_accept_linkedin" });
        // }
      }
    }, 1000);
  },
  created() {},
  computed: {},
  watch: {},
};
</script>

<style>
</style>