import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [


  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "LandingHome" */ '@/views/Home.vue'),
    props: true
  },

  {
    path: '/privacy',
    name: 'LandingPage_PrivacyPolicy',
    component: () => import( /* webpackChunkName: "PrivacyPolicy" */ '@/views/pages/PrivacyPolicy.vue'),
    props: true
  },

  {
    path: '/',
    name: 'LandingHome',
    component: () => import( /* webpackChunkName: "LandingHome" */ '@/views/Home.vue'),
    props: true
  },
  {
    path: '/contact',
    name: 'LandingContactOpen',
    component: () => import( /* webpackChunkName: "LandingHome" */ '@/views/Home.vue'),
    props: {
      contact_open: true,
      form_name: "ext_contact_link_15gg"
    }
  },
  {
    path: '/join/it',
    name: 'LandingHomeJoinProject',
    component: () => import( /* webpackChunkName: "LandingHome" */ '@/views/pages/landings/joinTheProject.vue'),
    props: {
      contact_open: true,
      form_name: "ext_contact_link_ea"
    }
  },
  {
    path: '/s/:scroll_to_section_id?',
    name: 'LandingHomeToSection',
    component: () => import( /* webpackChunkName: "LandingHome" */ '@/views/Home.vue'),
    props: true
  },
  {
    path: '/customers',
    name: 'LandingCustomers',
    component: () => import( /* webpackChunkName: "LandingCustomers" */ '@/views/Customers.vue'),
  },
  {
    path: '/newaccount',
    name: 'LandingNewAccount',
    component: () => import( /* webpackChunkName: "LandingNewAccount" */ '@/views/pages/createNewAccount.vue'),
  },
  {
    path: '/features',
    name: 'LandingFeatures',
    component: () => import( /* webpackChunkName: "LandingHowWorks" */ '@/views/pages/features.vue'),
  },
  {
    path: '/features/:from_section?',
    name: 'LandingFeatures',
    component: () => import( /* webpackChunkName: "LandingHowWorks" */ '@/views/pages/features.vue'),
  },
  {
    path: '/prices/:from_section?',
    name: 'LandingPrices',
    component: () => import( /* webpackChunkName: "LandingPrices" */ '@/views/pages/prices.vue'),
  },
  {
    path: '/createplant',
    name: 'LandingCreateNewPlant',
    component: () => import( /* webpackChunkName: "LandingCreateNewPlant" */ '@/views/CreateNewPlant.vue'),
  },


  {
    path: '/howto/areas/:from_section?',
    name: 'HowToManageAreas',
    component: () => import( /* webpackChunkName: "HowToManageAreas" */ '@/views/pages/howToManageAreas.vue'),
  },

  {
    path: '/howto/reports/:from_section?',
    name: 'HowToManageReports',
    component: () => import( /* webpackChunkName: "HowToManageReports" */ '@/views/pages/howToManageReports.vue'),
  },

  {
    path: '/howto/events',
    name: 'HowToManageEvents',
    component: () => import( /* webpackChunkName: "HowToManageEvents" */ '@/views/pages/howToManageEvents.vue'),
  },
  {
    path: '/howto/share/:from_section?',
    name: 'HowToManageSharing',
    component: () => import( /* webpackChunkName: "HowToManageSharing" */ '@/views/pages/howToManageSharing.vue'),
  },
  {
    path: '/howto/dashboard/:from_section?',
    name: 'HowToManageDashboard',
    component: () => import( /* webpackChunkName: "HowToManageDashboard" */ '@/views/pages/howToManageDashboard.vue'),
  },

  // Landings
  {
    path: '/ad/it/sicurezza-in-azienda',
    name: 'LandingPage_IT_PrimiClienti',
    component: () => import( /* webpackChunkName: "Landing_IT_PrimiClienti" */ '@/views/pages/landings/it/promuovi_sicurezza.vue'),
  },
  {
    path: '/ad/it/linkedin15',
    name: 'LandingPage_IT_Linkedin15',
    component: () => import( /* webpackChunkName: "LandingPage_IT_Linkedin15" */ '@/views/pages/landings/it/promo_linkedin_30gg_15p.vue'),
  },
  {
    path: '/ad/it/linkedin3015_31052022',
    name: 'LandingPage_IT_Linkedin15',
    component: () => import( /* webpackChunkName: "LandingPage_IT_Linkedin15" */ '@/views/pages/landings/it/promo_linkedin_30gg_15p.vue'),
  },
  {
    path: '/ad/it/lnkd_31052022',
    name: 'LandingPage_IT_Linkedin15',
    component: () => import( /* webpackChunkName: "LandingPage_IT_Linkedin15" */ '@/views/pages/landings/it/promo_linkedin_30gg_15p.vue'),
  },

  {
    path: '/ad/it/ea',
    name: 'LandingPage_IT_Linkedin15',
    component: () => import( /* webpackChunkName: "LandingPage_IT_Linkedin15" */ '@/views/pages/landings/it/promo_early_adopters.vue'),
  },

  // {
  //   path: '/ad/it/monregalese15',
  //   name: 'LandingPage_IT_Monregalese15',
  //   component: () => import(/* webpackChunkName: "LandingPage_IT_Monregalese15" */ '@/views/pages/landings/it/promo_monregalese_60gg_15p.vue'),
  // },
  {
    path: '/ad/it/monregalese6015',
    name: 'LandingPage_IT_Monregalese6015',
    component: () => import( /* webpackChunkName: "LandingPage_IT_Monregalese15" */ '@/views/pages/landings/it/promo_monregalese_60gg_15p.vue'),
  },


  {
    path: '/ad/it/ln/thankyou',
    name: 'LandingPage_IT_Contact_ThankYou_Linkedin',
    component: () => import( /* webpackChunkName: "LandingPage_IT_Contact_ThankYou_Linkedin" */ '@/views/pages/landings/it/contact_thankyou_linkedin.vue'),
  },


  // {
  //   path: '/ad/it/monregalese_15',
  //   name: 'LandingPage_IT_Monregalese15',
  //   component: () => import(/* webpackChunkName: "LandingPage_IT_Monregalese15" */ '@/views/pages/landings/it/promo_monregalese_60gg_15p.vue'),
  // },


  // 404
  {
    path: '*',
    name: 'Page404',
    component: () => import( /* webpackChunkName: "Page404" */ '../views/pages/Page404.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth',
    };
  }
})

export default router