<template>
  <div>
    <span
      v-bind:style="{
        'font-size': fontSize,
        'animation-delay': animationDelay,
      }"
      v-bind:class="isCurrentRoute ? 'luminance_current' : 'luminance_not_current'"
      class="hover-underline-animation"
    >
      {{ string }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    string: {
      type: String,
    },
    fontSize: {
      type: String,
    },
    animationDelay: {
      type: String,
    },
    isCurrentRoute: {
      type: Boolean,
    },
  },
  components: {},
  data: () => ({}),
  methods: {},
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped>
/*  */
.luminance_current {
  background: 50% 100%/50% 50% no-repeat
    radial-gradient(
      ellipse at bottom,
      rgb(255, 255, 0),
      transparent,
      transparent
    );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
  /* font-size: 2rem; */
  /* font-family: "Source Sans Pro", sans-serif; */
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms,
    glow-yellow 2s linear infinite 1s;
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow-yellow 2s linear infinite 1s;
  /* animation-delay: 1s; */
}
@-webkit-keyframes reveal {
  0% {
    /* background-position: top right; */
  }
  80% {
    /* letter-spacing: 8px; */
  }
  100% {
    /* background-position: bottom left; */
  }
}
@keyframes reveal {
  0% {
    /* background-position: top right; */
  }
  80% {
    /* letter-spacing: 8px; */
  }
  100% {
    /* background-position: bottom left; */
  }
}
@-webkit-keyframes glow-yellow {
  40% {
    text-shadow: 0 0 2px rgba(255, 255, 0, 0.75);
  }
}
@keyframes glow-yellow {
  40% {
    text-shadow: 0 0 2px rgba(255, 255, 0, 0.75);
  }
}


/*  */
.luminance_not_current {
  background: 50% 100%/50% 50% no-repeat
    radial-gradient(
      ellipse at bottom,
      rgb(207, 207, 207),
      transparent,
      transparent
    );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: bold;
  /* font-size: 2rem; */
  /* font-family: "Source Sans Pro", sans-serif; */
  -webkit-animation: reveal 3000ms ease-in-out forwards 200ms,
    glow-gray 2s linear infinite 1s;
  animation: reveal 3000ms ease-in-out forwards 200ms,
    glow-gray 2s linear infinite 1s;
  /* animation-delay: 1s; */
}
@-webkit-keyframes reveal {
  0% {
    background-size: 300% 300%;
  }
  80% {
    /* letter-spacing: 8px; */
  }
  100% {
    background-size: 300% 300%;
  }
}
@keyframes reveal {
  0% {
    background-size: 300% 300%;
  }
  80% {
    /* letter-spacing: 8px; */
  }
  100% {
    background-size: 300% 300%;
  }
}
@-webkit-keyframes glow-gray {
  40% {
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.683);
  }
}
@keyframes glow-gray {
  40% {
    text-shadow: 0 0 2px rgba(255, 255, 255, 0.683);
  }
}

/*  */
/*  */
/*  */

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.luminance_not_current.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #eeff00;
  transform-origin: bottom right;
  transition: transform 1s ease-out;
}

.luminance_current.hover-underline-animation:after {
  /* content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #c2c2c2;
  transform-origin: bottom right;
  transition: transform 1s ease-out; */
}


.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
</style>