<template>
  <div>
    <v-app>
      <!-- NOT Landing pages mount point -->
      <v-main class="black" v-if="isLandingPage == false">
        <v-progress-linear
          color="yellow"
          background-color="black"
          id="page-scroll-progress"
          :value="current_scroll_position"
          style="position: fixed; top: 0px; z-index: 500"
          height="5"
          name="progress_bar"
        ></v-progress-linear>
        <!-- <v-container
          style="position: relative"
          fluid
          fill-height
          class="ma-0 pa-0 bg-image fill-height"
          v-bind:class="isSmallDev ? '' : 'mt-0'"
          xs12
          sm10
          md12
          lg8
          xl4
        > -->

        <v-container
          class="pa-0"
          v-bind:class="isSmallDev ? '' : 'mt-0'"
          xs12
          sm10
          md12
          lg8
          xl4
          fluid
        >
          <v-layout align-center justify-center class="">
            <!-- <v-layout> -->
            <v-flex xs12 sm12 md12 lg12 xl8 class="">
              <topBarMenu />

              <div class="route-contents" id="route-content">
                <transition name="fade" mode="out-in" appear>
                  <router-view :key="'home_' + $route.fullPath" />
                  <!-- <router-view /> -->
                  <!-- <router-view class="mt-8" /> -->
                </transition>
              </div>

              <!-- </div> -->
            </v-flex>
          </v-layout>
        </v-container>

        <!-- <div style="height: 560px" class="black"></div> -->
        <pageFooter
          v-if="showFooter"
          data-aos="flip-up"
          data-aos-duration="500"
          class="mt-12"
        />
        <!-- <cookiePolicy /> -->

        <dialogCollectContact :header="$t('lp_dialog_cta_heading')" />

        <!-- <dialogCookie v-if="!cookies_decision" /> -->
      </v-main>

      <!-- Landing pages mount point -->
      <v-main v-if="isLandingPage == true">
        <v-progress-linear
          color="yellow"
          background-color="black"
          id="page-scroll-progress"
          :value="current_scroll_position"
          style="position: fixed; top: 0px; z-index: 500"
          height="5"
          name="progress_bar"
        ></v-progress-linear>

        <router-view />
      </v-main>

      <!-- <dialogCookie v-if="!cookies_decision" />-->
      <dialogCookie v-if="open_cookie" /> 
    </v-app>
  </div>
</template>

<script>
import topBarMenu from "@/components/landings/blocks/topBarMenu.vue";
// import { EventBus } from "./main";
import { i18n } from "@/plugins/i18n";
import update from "@/mixins/update";
// import { gtag } from "@/gtag";
import pageFooter from "@/components/landings/blocks/pageFooter.vue";

import dialogCollectContact from "@/components/landings/blocks/dialogCollectContact.vue";
import dialogCookie from "@/components/landings/blocks/dialogCookie.vue";
import { EventBus } from "@/main";

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  props: ["language"],
  mixins: [update],
  // head: {
  //   link: [
  //     {
  //       rel: "icon",
  //       href: require("@/assets/icons/favicon.png"),
  //     },
  //   ],
  // },
  metaInfo: {
    title: "Gestisci la sicurezza sul luogo di lavoro",
    titleTemplate: "SafetyFirst | %s",
    meta: [
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        // width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0
      },

      // // LINKEDIN SHARE
      // {
      //   property: "og:title",
      //   content: "SafetyFirst",
      // },
      // {
      //   property: "og:image",
      //   content: "//sa-first.com/img/social_share_centered2.png",
      // },

      // {
      //   property: "og:description",
      //   content:
      //     "Migliora la sicurezza sul lavoro utilizzando una comunicazione inclusiva",
      // },

      // {
      //   property: "og:url",
      //   content: "//sa-first.com/",
      // },
      // //


    ],
  },
  components: {
    // lateralLandingDrawer,
    topBarMenu,
    pageFooter,
    dialogCollectContact,
    dialogCookie,
  },
  data: () => ({
    // languages: languages,
    current_scroll_position: 0,
    open_cookie:false,
    //
    // cookie_dialog: false,
    // cookies: {
    //   analytics: true,
    // },
    // //
    // cookie_settings: false,
    cookies_decision: null,
  }),
  methods: {
    getScrollPercent() {
      var h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight";
      var percent =
        ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100;
      return Math.round(percent / 5) * 5;
    },
  },
  mounted() {

    EventBus.$on("open_cookie_setting", () => {
      console.log('open_cookie');
      this.open_cookie = true;
    });

    this.cookies_decision = getCookie("lp_cookies_decision");
    console.log("mounted app.web website");
    // Set i18n language based on url language params
    // If parmas is en -> us
    // If params is not 'us' or 'it' set to 'it'
    // console.log("route lang", this.language);
    let language_to_set = this.language;
    if (this.language) {
      if (this.language == "en") {
        language_to_set = "us";
      }
      if (this.language != "us" && this.language != "it") {
        language_to_set = "it";
      }
      i18n.locale = language_to_set;
    }
    window.addEventListener("scroll", () => {
      this.current_scroll_position = parseInt(this.getScrollPercent());
    });
  },
  created() {},
  computed: {
    isLandingPage() {
      if (this.$route.name) {
        if (this.$route.name.includes("LandingPage_")) {
          console.log("landing page");
          return true;
        } else {
          console.log("site page");
          return false;
        }
      }
    },
    showFooter() {
      if (this.$route.name && this.$route.name.includes("Page404")) {
        return false;
      }
      return true;
    },
  },
};
</script>



<style>
.route-contents {
  position: relative;
  /* margin-top: 64px; */
  /* background:white; */
  /* top: 64px; */
  /* background-color: rgba(0, 0, 0, 1); */
}
</style>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.275s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.cookie_message {
  line-height: 1;
}
</style>

<style>
.v-dialog.v-dialog--active {
  border-radius: 0 !important;
  margin: 0 !important;
  width: 100vw;
}
.firebase-emulator-warning {
  display: none !important;
}
</style>