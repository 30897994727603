let route_from_name

export const routing_beforeEnter = {
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.route_from_name = from.name;
            route_from_name = from.name;
        });
    },
    methods: {
        routeBack() {
            console.log('Back to', route_from_name );
            if (route_from_name != null) {
                this.$router.push({ name: route_from_name });
            } else {
                this.$router.push({ name: 'Home' });
            }
        },
    },
    computed: {
        currentRouteName() {
            return this.$router.currentRoute.name
        },
        currentFullRoute() {
            return this.$router.currentRoute
        }

    }
}








