import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
          xs: 600,
          sm: 960,
        //   md: 1264,
        //   md: 1350,
        //   lg: 2400, 
        },
        scrollBarWidth: 16,
      },
    theme: {
        themes: {
            light: {
                primary: '#2b2b2b',
                secondary: '#ffee00',
                accent: '#8c9eff',
                error: '#b71c1c',
            },
        },
    },
});
