export default {
    data() {
        return {
            // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,
            autoReloadViews: [
                'DashboardFullscreen',
                'DashboardLanguageByPlantName',
                'DashboardLanguage',
                'Dashboard',
                'ViewAreaQrcodeStandalone',
                'ViewAreaBtnStandalone',
                'LandingHomeNoLanguage',
                'LandingHome'
            ]
        }
    },
    created() {
        // Listen for our custom event from the SW registration
        // console.log('start listener for swUpdated');
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })

        // // Prevent multiple refreshes
        // navigator.serviceWorker.addEventListener('controllerchange', () => {
        //     if (this.refreshing) return
        //     this.refreshing = true
        //     // Here the actual reload of the page occurs
        //     window.location.reload()
        // })
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            console.log('updateAvailable');
            console.log(event);
            this.registration = event.detail

            // // In case of dashboard refresh without request 
            // if (this.$route.name == "DashboardFullscreen") this.refreshApp();
            this.updateExists = true
            if (this.autoReloadViews.includes(this.$route.name)) {
                console.log('auto rerefesh in 3 seconds');
                setTimeout(() => {
                    this.refreshApp();
                }, 3000);
            } else {
                this.updateExists = true
            }
        },

        // Called when the user accepts the update
        refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
    },

}