import Vue from 'vue';
import VueI18n from 'vue-i18n';

// import it_app from '@/assets/translations/app/it.json';
// import us_app from '@/assets/translations/app/us.json';


// import it_app_pictograms from '@/assets/translations/app/it_pictograms.json';
// import us_app_pictograms from '@/assets/translations/app/us_pictograms.json';

import it_lp_headings from '@/assets/translations/landingpages/it_lp_headings_1.json';
import us_lp_headings from '@/assets/translations/landingpages/us_lp_headings_1.json';

import it_lp_app_features from '@/assets/translations/landingpages/it_lp_app_features.json';
import us_lp_app_features from '@/assets/translations/landingpages/us_lp_app_features.json';

import it_lp_pages from '@/assets/translations/landingpages/it_lp_pages.json';
import us_lp_pages from '@/assets/translations/landingpages/us_lp_pages.json';

Vue.use(VueI18n);


const it = Object.assign(it_lp_headings, it_lp_app_features, it_lp_pages)
const us = Object.assign(us_lp_headings, us_lp_app_features, us_lp_pages)


const messages = {
    'us': us,
    'it': it
};

export const i18n = new VueI18n({
    locale: 'it', // set locale
    fallbackLocale: 'us', // set fallback locale
    messages, // set locale messages
});



export const languages = [
    { flag: "us", language: "en", title: "English" },
    { flag: "it", language: "it", title: "Italiano" },
]