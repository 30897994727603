<template>
  <div>
    <v-dialog
      v-model="dialogCollectData"
      scrollable
      persistent
      transition="dialog-transition"
      :fullscreen="$vuetify.breakpoint.xs"
      max-width="800"
      style="z-index: 600"
      overlay-opacity="0.75"
      overlay-color="black"
    >
      <v-card
        v-bind:class="
          $vuetify.breakpoint.xs ? '' : 'card_collect_contact_border'
        "
        class="card_collect_contact_background"
        :tile="$vuetify.breakpoint.xs"
      >
        <v-card-title
          color="black"
          class="card_collect_contact_title"
          style="word-break: break-word"
        >
          <v-row no-gutters>
            <v-col cols="11">
              <h4 v-html="header">
                <!-- {{ header }} -->
              </h4>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn icon @click="closeDialog(false)">
                <v-icon color="white"> mdi-close </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mx-0 px-0 mb-0 pb-0" id="form_container">
          <div id="card_top">
            <!-- Layout dialog contact -->
            <v-row no-gutters>
              <!-- Text area -->
              <v-col cols="12" md="6" class="mt-2 px-4">
                <featuresForCollectContact class="mb-4" />
              </v-col>
              <!-- Form area -->
              <v-col
                cols="12"
                md="6"
                class="
                  px-4 px-4
                  card_collect_contact_form_background
                  col-md-6 col-12
                "
              >
                <v-row>
                  <v-col cols="12" class="mt-8">
                    <span
                      ><strong>
                        {{ $t("lp_dialog_cta_span_contact_data") }}
                      </strong></span
                    >
                  </v-col>
                </v-row>

                <!-- NAME -->
                <v-row>
                  <v-col cols="12" class="pb-0 mb-0">
                    <v-text-field
                      v-model.trim="contactName"
                      :label="$t('lp_label_name')"
                      :error-messages="error_contactName"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- LASTNAME -->
                <v-row>
                  <v-col cols="12" class="pb-0 mb-0">
                    <v-text-field
                      v-model.trim="contactLastname"
                      :label="$t('lp_label_lastname')"
                      :error-messages="error_contactLastname"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- ROLE -->
                <v-row>
                  <v-col cols="12" class="pb-0 mb-0">
                    <v-select
                      :items="company_roles"
                      :label="$t('lp_label_roles')"
                      dense
                      persistent-hint
                      v-model="selectedRole"
                      :error-messages="error_selectedRole"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <!-- EMAIL -->
                <v-row>
                  <v-col cols="12" class="pb-0 mb-0">
                    <v-text-field
                      v-model.trim="email"
                      :label="$t('lp_label_email')"
                      :error-messages="error_email"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="form_data">
                  <v-row>
                    <v-col cols="12" class="mt-2">
                      <span
                        ><strong>
                          {{ $t("lp_dialog_cta_span_company_register") }}
                        </strong></span
                      >
                    </v-col>
                  </v-row>
                  <!-- COMPANY NAME -->
                  <v-row class="mt-4">
                    <v-col cols="12" class="pb-0 mb-0">
                      <v-text-field
                        v-model.trim="company_name"
                        :label="$t('lp_label_company_name')"
                        :error-messages="error_company_name"
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <!-- REGIION & PROVINCE -->
                  <v-row class="mt-4">
                    <v-col cols="12" sm="6" class="pb-0 mb-0 mt-0 pt-0">
                      <v-select
                        :items="regioni()"
                        :label="$t('lp_label_region')"
                        dense
                        persistent-hint
                        v-model="selectedRegion"
                        :error-messages="error_selectedRegion"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 mb-0 mt-0 pt-0">
                      <v-select
                        :items="province"
                        :label="$t('lp_label_province')"
                        dense
                        persistent-hint
                        v-model="selectedProvince"
                        :error-messages="error_selectedProvince"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <!-- COMPANY SIZE AND PLANTS COUNT -->
                  <v-row>
                    <v-col cols="12" sm="6" class="pb-0 mb-2 mt-0 pt-0">
                      <v-select
                        :items="company_sizes"
                        :label="$t('lp_label_company_size')"
                        dense
                        persistent-hint
                        :messages="$t('label_new_plant_employees_message_1')"
                        v-model="selectedCompanySize"
                        :error-messages="error_selectedCompanySize"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0 mb-0 mt-0 pt-0">
                      <v-select
                        :items="company_plants"
                        :label="$t('lp_label_company_plants_count')"
                        dense
                        persistent-hint
                        :messages="
                          $t('lp_label_company_plants_count_description')
                        "
                        v-model="selectedPlantCount"
                        :error-messages="error_selectedPlantCount"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mb-0 pb-0">
                    <v-col cols="12" class="mt-0 pt-0">
                      <v-checkbox v-model="comunicationViaEmail">
                        <template v-slot:label>
                          <span style="font-size: 0.8rem">{{
                            $t("lp_label_receive_comunication_mail")
                          }}</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="card_collect_contact_form_background_actions">
          <v-spacer />
          <v-btn
            min-width="200"
            color="black"
            @click="formValidate()"
            :disabled="sendingContact"
            :loading="sendingContact"
          >
            <span class="yellow--text">
              {{ $t("lp_dialog_cta_btn_send_request") }}
            </span>
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogCollected"
      scrollable
      persistent
      transition="dialog-transition"
      max-width="500"
      style="z-index: 600"
      overlay-opacity="0.75"
      overlay-color="black"
    >
      <v-card class="card_collect_contact_border">
        <v-card-title
          color="black"
          class="card_collect_contact_title"
          style="word-break: break-word"
        >
          {{ $t("lp_span_message_collected_contact_1") }}
        </v-card-title>
        <v-card-text class="pt-8">
          {{ $t("lp_span_message_collected_contact_2") }}
          <br />
          <br />
          {{ $t("lp_span_message_collected_contact_3") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn min-width="150" outlined @click="closeDialog()">
            {{ $t("btn_close") }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import collectContact from "./collectContact.vue";
import { EventBus } from "@/main";
import { findWithAttr } from "@/modules/find_with_attr";
import { gtag } from "@/gtag";
import { urlRequestEndpoint } from "@/plugins/firebase";

import featuresForCollectContact from "./featuresForCollectContact";

import {
  geoData,
  company_plants,
  company_sizes,
  company_roles,
} from "./collectData.js";

function validateEmail(mail) {
  // console.log(mail);
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      mail
    )
  ) {
    return true;
  }
  return false;
}

export default {
  props: {
    header: {
      type: String,
    },
    formName: {
      type: String,
    },
  },
  components: {
    featuresForCollectContact,
  },
  data: () => ({
    // header: null,
    //

    geoData: geoData,
    company_plants: company_plants,
    company_sizes: company_sizes,
    company_roles: company_roles,
    sendingContact: false,
    //
    dialogCollectData: false,
    dialogCollected: false,
    //
    calling_section: null,
    //
    showTooltipCompanySize: false,
    //
    email: null,
    contactName: null,
    contactLastname: null,
    company_name: null,
    selectedRegion: null,
    selectedProvince: null,
    selectedCompanySize: null,
    selectedPlantCount: null,
    selectedRole: null,
    comunicationViaEmail: true,
    //
    // email: "alessandro.mendolia@gmail.com",
    // company_name: "AVMSquare",
    // selectedRegion: "Piemonte",
    // selectedProvince: "Cuneo",
    // selectedCompanySize: "50 - 150",
    // selectedPlantCount: "1",
    // comunicationViaEmail: true,
    //

    error_contactName: null,
    error_contactLastname: null,

    error_email: null,
    error_company_name: null,
    error_selectedRegion: null,
    error_selectedProvince: null,
    error_selectedCompanySize: null,
    error_selectedPlantCount: null,
    error_selectedRole: null,
    //
  }),
  methods: {
    scroll_top() {
      const element = document.getElementById("form_container");
      if (element) element.scroll({ top: 0, behavior: "smooth" });
    },

    closeDialog(after_send = true) {
      this.dialogCollectData = false;
      this.dialogCollected = false;

      if (after_send != true) {
        gtag("event", "contact_dialog_abort", {
          form_id: this.formName,
        });
      }
    },

    formValidate() {
      let formValid = true;
      if (validateEmail(this.email) != true) {
        this.error_email = this.$t("lp_error_email");
        formValid = false;
      }

      if (!this.contactName) {
        this.error_contactName = this.$t("lp_label_contactName_error");
        formValid = false;
      }

      if (!this.contactLastname) {
        this.error_contactLastname = this.$t("lp_label_contactLastname_error");
        formValid = false;
      }

      if (!this.company_name) {
        this.error_company_name = this.$t("lp_label_company_name_error");
        formValid = false;
      }

      if (!this.selectedRole) {
        this.error_selectedRole = this.$t("lp_label_role_error");
        formValid = false;
      }

      if (!this.selectedRegion) {
        this.error_selectedRegion = this.$t("lp_label_region_error");
        formValid = false;
      }
      if (!this.selectedProvince || this.selectedProvince == "") {
        this.error_selectedProvince = this.$t("lp_label_province_error");
        formValid = false;
      }

      if (!this.selectedCompanySize) {
        this.error_selectedCompanySize = this.$t("lp_label_company_size_error");
        formValid = false;
      }

      if (!this.selectedPlantCount) {
        this.error_selectedPlantCount = this.$t(
          "lp_label_company_plants_count_error"
        );
        formValid = false;
      }

      if (formValid) this.saveData();
    },

    regioni() {
      let regioni = [];
      this.geoData.forEach((element) => {
        regioni.push(element.nome);
      });
      return regioni;
    },

    saveData() {
      this.sendingContact = true;
      fetch(urlRequestEndpoint + "/registerNewContact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          contact_name: this.contactName,
          contact_lastname: this.contactLastname,
          email: this.email,
          company_name: this.company_name,
          region: this.selectedRegion, //
          province: this.selectedProvince, //
          company_size: this.selectedCompanySize, //
          plant_count: this.selectedPlantCount, //
          comunication_email: this.comunicationViaEmail,
          form_origin: this.formName,
          company_role: this.selectedRole,
        }),
      })
        // .then((response) => response.json())
        .then(() => {
          setTimeout(() => {
            EventBus.$emit("contactSent");
          }, 5000);

          gtag("event", "contact_submit", {
            form_id: this.formName,
            contact_province: this.selectedProvince,
            contact_region: this.selectedRegion,
            role: this.selectedRole,
          });

          // this.$gtag("event", "contact_submit", {
          //   form_id: this.formName,
          //   contact_province: this.selectedProvince,
          //   contact_region: this.selectedRegion,
          // });

          localStorage.setItem("contactSent", 1);
          this.dialogCollectData = false;
          this.sendingContact = false;
          this.dialogCollected = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cleanForm() {
      this.contactName = null;
      this.contactLastname = null;
      this.email = null;
      this.company_name = null;
      this.selectedRegion = null;
      this.selectedProvince = null;
      this.selectedCompanySize = null;
      this.selectedPlantCount = null;
      this.comunicationViaEmail = true;
      this.selectedRole = null;
      //
      this.error_contactName = null;
      this.error_contactLastname = null;
      this.error_email = null;
      this.error_company_name = null;
      this.error_selectedRegion = null;
      this.error_selectedProvince = null;
      this.error_selectedCompanySize = null;
      this.error_selectedPlantCount = null;
      this.error_selectedRole = null;
    },
  },
  mounted() {
    EventBus.$on("cta_contact_collect", (calling_section) => {
      this.calling_section = calling_section;
      this.dialogCollectData = true;
      this.dialogCollected = false;
      this.sendingContact = false;
      gtag("event", "contact_dialog_open", {
        form_id: this.formName,
      });

      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: "contact_dialog_open_TEST",
      //   form_id: "TEST_FORM_ID",
      // });

      this.cleanForm();
      setTimeout(() => {
        this.scroll_top();
      }, 100);
    });
  },
  created() {},
  computed: {
    province() {
      const index_region = findWithAttr(
        this.geoData,
        "nome",
        this.selectedRegion
      );
      if (index_region > -1) {
        return this.geoData[index_region].capoluoghi;
      } else {
        return [];
      }
    },
  },
  watch: {
    contactName() {
      this.error_contactName = null;
    },
    contactLastname() {
      this.error_contactLastname = null;
    },
    selectedRole() {
      this.error_selectedRole = null;
    },
    email() {
      this.error_email = null;
    },
    company_name() {
      this.error_company_name = null;
    },
    selectedRegion() {
      this.error_selectedRegion = null;
      this.selectedProvince = null;
    },
    selectedProvince() {
      this.error_selectedProvince = null;
    },
    selectedCompanySize() {
      this.error_selectedCompanySize = null;
    },
    selectedPlantCount() {
      this.error_selectedPlantCount = null;
    },
  },
};
</script>

<style scoped>
.card_collect_contact_background {
  background: white;
}

.card_collect_contact_border {
  border-width: 2px;
  border-color: white;
  border-style: solid;
}

.card_collect_contact_title {
  color: white;
  background: rgb(31, 31, 31);
}

.card_collect_contact_form_background,
.card_collect_contact_form_background_actions {
  background-color: #0000000f;
}
</style>