<template>
  <div>
    <span>
      <strong>
        {{ $t("lp_span_message_feature_for_collect_contact") }}
      </strong>
    </span>
    <ul class="mt-4">
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_1") }}
      </li>
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_2") }}
      </li>
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_3") }}
      </li>
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_4") }}
      </li>
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_5") }}
      </li>
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_6") }}
      </li>
      <li>
        <v-icon color="#008505">mdi-check</v-icon>
        {{ $t("lp_li_collect_contact_7") }}
      </li>
      <li class="pl-7">
        <!-- <v-icon color="#008505">mdi-check</v-icon> -->
        {{ $t("lp_li_collect_contact_8") }}
      </li>

    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
ul {
  list-style-type: none;
}
</style>