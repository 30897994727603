import { i18n } from '@/plugins/i18n';

export const computed_landing_routes = {
    computed: {
        landing_routes() {
            const routes = [
                {
                    text: i18n.messages[i18n.locale]['lp_page_home'],
                    name: "LandingHome"
                },
                {
                    text: i18n.messages[i18n.locale]['lp_page_howworks'],
                    name: "LandingFeatures"
                },
                // {
                //     text: i18n.messages[i18n.locale]['lp_page_prices'],
                //     name: "LandingPrices"
                // },
                // {
                //     text: i18n.messages[i18n.locale]['lp_page_customers'],
                //     name: "LandingCustomers"
                // },
            ]
            return routes
        }
    }
}